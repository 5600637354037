<template>
    <div class="overlay popup-style">
        <v-container fluid class="h-full p-0">
            <v-row v-if="currentOs = true" align="center" justify="center" class="h-full">
                <v-col>
                    <div class="dialog">
                        <app-icon size="15" icon="close" @click="$emit('no')"></app-icon>
                        <div class="popup-img">
                            <img
                                src="@/assets/popupicons/apple-icon-precomposed.png"
                                alt="noodzly logo"
                                style="width: 150px"
                            />
                        </div>
                        <div class="popup-inner">
                            <h4>Get Noodzly App</h4>
                            <p>Access Noodzly quickly with the app on your home screen.</p>
                            <!-- <p>On iOS, use the Safari browser.</p> -->
                            <!-- <div class="popup-direction">
                                <arrow-popup></arrow-popup>
                                <p>Top Right <br> Corner</p>
                            </div> -->
                        </div>
                        <div class="popup-cta">
                            <div>
                                Tap
                                <share-popup></share-popup>
                            </div>
                            <div>
                                Add to Home Screen
                                <plus-popup></plus-popup>
                            </div>
                        </div>
                        <slot name="afterButtons"></slot>
                    </div>
                </v-col>
            </v-row>

            <v-row v-else align="center" justify="center" class="h-full">
                <v-col>
                    <div class="dialog">
                        <app-icon size="15" icon="close" @click="$emit('no')"></app-icon>
                        <div class="popup-img">
                            <img
                                src="@/assets/popupicons/apple-icon-precomposed.png"
                                alt="noodzly logo"
                                style="width: 150px"
                            />
                            <!-- <img
                                src="@/assets/images/logo/noodzly-logo.png"
                                alt="noodzly logo"
                                style="width: 150px"
                            /> -->
                        </div>
                        <div class="popup-inner">
                            <h4>Get Noodzly App</h4>
                            <p>Access Noodzly quickly with the app on your home screen.</p>
                            <div class="popup-direction">
                                <arrow-popup></arrow-popup>
                                <p>Top Right <br> Corner</p>
                            </div>
                        </div>
                        <div class="popup-cta">
                            <div>
                                Tap
                                <share-popup></share-popup>
                            </div>
                            <div>
                                Add to Home Screen
                                <plus-popup></plus-popup>
                            </div>
                        </div>
                        <slot name="afterButtons"></slot>
                    </div>
                </v-col>
            </v-row> 
        </v-container>
    </div>
</template>

<script>
import ArrowPopup from "@/assets/images/icons/arrow-popup.svg?inline";
import SharePopup from "@/assets/images/icons/share-popup.svg?inline";
import PlusPopup from "@/assets/images/icons/plus-popup.svg?inline";

export default {
    name: "InstructionPopup",
    data: () => ({
        currentOs: null,
    }),
    
    components: {
        ArrowPopup,
        SharePopup,
        PlusPopup,
    },

    mounted() {
        this.checkPWAInstallation();
        const   userAgent = window.navigator.userAgent,
                platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
                macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        
        if (macosPlatforms.indexOf(platform) !== -1) {
            this.currentOs = true
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            this.currentOs = true
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            this.currentOs = false
        } else if (/Android/.test(userAgent)) {
            this.currentOs = false
        } else if (/Linux/.test(platform)) {
            this.currentOs = false
        }
    },

    methods: {
        checkPWAInstallation() {
            // Check if PWA is installed (iOS Safari check)
            if (navigator.standalone) {
                this.isPWAInstalled = true;
                console.log("PWA is installed (iOS standalone)");
            } 
            // Check for display mode on Android and other browsers
            else if (window.matchMedia('(display-mode: standalone)').matches) {
                this.isPWAInstalled = true;
                console.log("PWA is installed (Android standalone)");
            } 
            // Fallback: PWA is not installed
            else {
                console.log("PWA is not installed");
            }
        },
    },
}

</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';
.popup-style .container { padding: 30px 30px 0px; }
.popup-style .container .col { padding: 0px; }
.popup-style .container .col .dialog { min-height: 320px; background: #1E1B20; border-radius: 14px; padding: 30px; position: relative; }
.popup-style .container .col .dialog > i { position: absolute; right: 14px; top: 14px; }
.popup-style .container .col .dialog .popup-inner { text-align: center; }
.popup-style .container .col .dialog .popup-inner h4 { font-size: 24px; margin-bottom: 15px; }
.popup-style .container .col .dialog .popup-direction { margin-bottom: 25px; }
.popup-style .container .col .dialog .popup-direction svg { position: absolute; left: 96px; }
.popup-style .container .col .dialog .popup-direction p { display: flex; justify-content: flex-end; text-align: left; font-size: 18px; padding-right: 30px; }
.popup-style .container .col .dialog .popup-cta { display: flex; justify-content: space-between; flex-direction: row; padding: 10px; background-color: #262328; border-radius: 14px; }
.popup-style .container .col .dialog .popup-cta { font-size: 16px; }
.popup-style .container .col .dialog .popup-cta div { font-size: 15px; display: flex; align-items: center; gap: 15px; position: relative; }
.popup-style .container .col .dialog .popup-cta div::after { height: 15px; width: 20px; content:""; position: absolute; top: 50%; right: -50%; transform: translate(50%, -50%); background-image: url('../../../assets/images/icons/next-popup.svg'); background-position: center; background-size: cover; background-repeat: no-repeat; }
.popup-style .container .col .dialog .popup-cta div svg { height: 20px; width: 20px; fill: #0F4188; }
.popup-style .container .col .dialog .popup-img { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 0; }
.popup-style .container .col .dialog .popup-img img:first-child { width: 60px !important; }

@media only screen and (max-width: 380px) { 
    .popup-style .container .col .dialog { padding: 30px 20px; }
    .popup-style .container .col .dialog .popup-cta div::after { height: 13px; width: 18px; right: -75%; }
    .popup-style .container .col .dialog .popup-cta div { font-size: 13px; gap: 6px; line-height: 1; }
 }

.yes{
    text-align: end;
}
.choose{
    border: 1px solid white;
    border-radius: 13px;
    transition: 0.3s;
    cursor: pointer;
}
.choose:active{
    border: 1px solid white;
    border-radius: 13px;
    background: rgba(255,255,255,0.3);
}
.link {
    text-decoration: none;
    color: white;
    font-size: 1.3em;
}

.overlay {
    position: fixed;
    background: rgba(52, 52, 65, 0.88);
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.dialog {
    background: $accent-background;
    min-height: 140px;
    border-radius: 14px;
}
.title{
    font-size: 1.3em;
}
</style>
